"use client"

import { useThemeContext } from "@/app/theme-provider";
import DefaultPackageItem from "./DefaultPackageItem";
import { useEffect, useState } from "react";
import PackagesLoader from "@/components/PackagesLoader";

export default function PackagesNew({ defaultTheme, main = false }) {

    const { shortcodeDetails } = useThemeContext();

    const [packages, setPackages] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch('/api/packages/');
            const { data } = await response.json();

            setPackages(data)
        }

        fetchData();
    }, [])

    const packageFeatures = [
        {
            id: 1,
            name: "1-Month",
            features: [
                `${shortcodeDetails?.servers_count} servers`,
                `${shortcodeDetails?.countries_count} countries`,
                "Streaming servers",
                "Unlimited data",
                "Unlimited server switching",
                "Best-in-class encryption",
                `${shortcodeDetails?.multi_logins} multi-logins`,
                `${shortcodeDetails?.money_back} money-back guarantee`,
            ]
        },
        {
            id: 2,
            name: "Lifetime",
            features: [
                `${shortcodeDetails?.servers_count} servers`,
                `${shortcodeDetails?.countries_count} countries`,
                "Streaming servers",
                "200GB/month data",
                "Unlimited server switching",
                "Best-in-class encryption",
                `${shortcodeDetails?.multi_logins} multi-logins`,
                `${shortcodeDetails?.money_back} money-back guarantee`,
            ]
        },
        {
            id: 3,
            name: "1-Year",
            features: [
                `${shortcodeDetails?.servers_count} servers`,
                `${shortcodeDetails?.countries_count} countries`,
                "Streaming servers",
                "200GB/month data",
                "Unlimited server switching",
                "Best-in-class encryption",
                `${shortcodeDetails?.multi_logins} multi-logins`,
                `${shortcodeDetails?.money_back} money-back guarantee`,
            ]
        }
    ];

    const renderItem = (item) => {

        let features = packageFeatures.filter(p => p.name === item?.package?.name)[0];

        switch (defaultTheme) {
            default: return <DefaultPackageItem
                key={item.package_id}
                item={item}
                features={features?.features}
                shortcodeDetails={shortcodeDetails}
            />
        }
    }

    return (
        <section className={["pricing-sect", !main ? 'transparent' : null].join(' ')}>
            <div className="in-pricing-sect">
                <div className="container">
                    <div className="pricing-row">
                        {packages.length == 0 && (
                            <>
                                <PackagesLoader /><PackagesLoader /><PackagesLoader />
                            </>
                        )}
                        {packages.length > 0 && (
                            packages && packages.map((item) => (
                                renderItem(item)
                            )))
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}