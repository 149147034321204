import(/* webpackMode: "eager" */ "/home/forge/nextapp.oystervpn.co/components/BubbleAnimation.js");
import(/* webpackMode: "eager" */ "/home/forge/nextapp.oystervpn.co/components/BuyButton.js");
import(/* webpackMode: "eager" */ "/home/forge/nextapp.oystervpn.co/components/CustomerSupport.js");
import(/* webpackMode: "eager" */ "/home/forge/nextapp.oystervpn.co/components/FAQItem.js");
import(/* webpackMode: "eager" */ "/home/forge/nextapp.oystervpn.co/components/FAQs.js");
import(/* webpackMode: "eager" */ "/home/forge/nextapp.oystervpn.co/components/FeaturesList.js");
import(/* webpackMode: "eager" */ "/home/forge/nextapp.oystervpn.co/components/PricingPlans.js");
import(/* webpackMode: "eager" */ "/home/forge/nextapp.oystervpn.co/components/ServersMap.js");
import(/* webpackMode: "eager" */ "/home/forge/nextapp.oystervpn.co/components/SVGImage.js");
import(/* webpackMode: "eager" */ "/home/forge/nextapp.oystervpn.co/node_modules/.pnpm/next@13.5.6_@babel+core@7.23.5_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/forge/nextapp.oystervpn.co/node_modules/.pnpm/next@13.5.6_@babel+core@7.23.5_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/forge/nextapp.oystervpn.co/node_modules/.pnpm/next@13.5.6_@babel+core@7.23.5_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js")